/*
 * MKW Framework
 * 2018-2019
 */

(function ($) {
    'use strict';

    // Window Global Variables 
    var WinEventsOptions = {
        scrollDelay: 100,
        resizeDelay: 350
    };
    var winEvents = new WindowEvents(WinEventsOptions);

    var MKW = {};
    var viewPort = $('html, body');
    TweenMax.ticker.useRAF(false);
    TweenMax.ticker.fps(60);




    var MKW = {

        debug: false,

        init: function (debug = false) {

            this.debug = debug;

            this.initHeader();
            this.initContentJS();
            this.initMobile();
            this.initWindowEvents();
            this.initParallax();
            this.initSystem();
            this.initMenu();
            this.initEventsTabs();
            this.initForm();

        },

        initSystem: function () {

            if (this.debug)
                console.log('Module Executed: System');


            // Remove loader on finishing loading every element
            winEvents.on('load.complete', function (winData) {
                MKW.loaderOff();
            });


        },

        initMenu:function(){
            if (this.debug)
                console.log('Module Executed: MENU');

            var menuList = $('.header-menu--list');
            var menuLine = $('.header-menu--line');

            if(!menuList.length && !menuLine.length)
                return;

            var GoToTarget = function($target){

                var newHash = '#'+$target;


                window.location.hash = newHash;

                // Replace Active Status
                menuList.find('a.is-active').removeClass('is-active');
                menuLine.find('a.is-active').removeClass('is-active');

                menuList.find('a[data-target*='+$target+']').addClass('is-active');
                menuLine.find('a[data-target*='+$target+']').addClass('is-active');

                var location = $('[data-location*="'+$target+'"]');

               if(location.length){
                   TweenMax.to(window, 0.7, {scrollTo: location.offset().top - 160});
               }
               if($target=='start'){
                   TweenMax.to(window, 0.7, {scrollTo: 0});
               }
                console.log(location);
            };


            $('a[data-target]').on('click',function (e) {
                e.preventDefault();
                var target = $(this).attr('data-target');

                GoToTarget(target);

                return;
            });

            // On load

            var hash = window.location.hash;
            var location = $('[data-location*="'+hash.replace('#','')+'"]');

            setTimeout(function () {
                if(location.length){
                    TweenMax.to(window, 0.7, {scrollTo: location.offset().top - 160});
                }
                if(hash.replace('#','')=='start'){
                    TweenMax.to(window, 0.7, {scrollTo: 0});
                }
            },500);




        },

        initMobile: function () {

            if (this.debug)
                console.log('Module Executed: Mobile');


        },

        initHeader: function () {

            if (this.debug)
                console.log('Module Executed: Header');
            
            // Fix the header on Desktop
            $('.js-header').scrollToFixed({
                spacerClass: 'fix-spacer',
                zIndex: 2000,
            });
            
            $(window).scroll(function(){
                  var scroll = $(window).scrollTop();
                  
                  if(scroll > 100){
                      $('.js-header').addClass('is-scroll');
                  }else{
                       $('.js-header').removeClass('is-scroll');
                  }
                  
            });
                

        },

        initContentJS: function () {

            if (this.debug)
                console.log('Module Executed: Content Javascript ');

            $('[data-copy]').on('click touchstart',function(){
                var info = $(this).attr('data-copy');
                var $temp = $("<input />");
                $("body").append($temp);
                $temp.val(info).select();
                document.execCommand("copy");
                $temp.remove();
            });

            $('.form-wrapper input:not([type=checkbox]),.form-wrapper span').on('click focus change',function () {
                var Col = $(this).parent();
                var input = Col.find('input');
                Col.addClass('is-set');
                input.removeClass('has-error');

            });
            $('.form-wrapper input:not([type=checkbox])').on('blur',function () {
                if($(this).val()===""){
                    $(this).parent().removeClass('is-set');
                }
            });


        },

        initParallax: function () {



            $('.js-main').mousemove(function (event) {

                var xPos = (event.clientX / $(window).width()) * 50;
                var box = $('.js-parallax');

                TweenMax.to(box, 0.6, {
                    right: xPos,
                    ease: Power1.easeOut,
                    //transformPerspective: 900,
                    //transformOrigin: 'center'
                });


                var motiveImage = $('.motive-image img');

                if(motiveImage.length){
                    TweenMax.to(motiveImage,0.6, {
                        right: xPos - 40,
                        ease: Power1.easeOut,
                        //transformPerspective: 900,
                        //transformOrigin: 'center'
                    });
                }

            });
        },

        loaderOn: function (speed = 700, fn = function() { }) {
            if (this.debug)
                console.log('Loader :: Showing');

            $('.js-loader').removeClass('loader-logo-clear');
            $('.js-loader').fadeIn(speed, function () {
                $('.main-container').removeClass('is-set');
            });

            setTimeout(fn, speed + 300);


        },

        loaderOff: function (speed = 700, fn = function() { }) {
            if (this.debug)
                console.log('Loader :: Hideing');
           if(!$('.js-loader').length)
               return;
           
            $('.js-loader-skip,.js-loader').click(function () {
                $('.js-loader').fadeOut(speed, function () {
                    $('.main-container').addClass('is-set');
                });
            })

            document.getElementById('loader-video').addEventListener('ended', function () {
                $('.js-loader').fadeOut(speed, function () {
                    $('.main-container').addClass('is-set');
                });
            }, false);


        },

        initEventsTabs:function(){

            if(!$('.events').length)
                return;

            $('.events-days-nav button').on('click',function(){
                var ID = '.events-days #'+$(this).attr('id').replace('nav-','');

                $('.events-days-nav button.is-active').removeClass('is-active');
                $(this).addClass('is-active');

                $('.events-days > div.is-active').removeClass('is-active').fadeOut(400,function(){
                    $(ID).insertBefore($('.events-days>div:last'));
                    $(ID).fadeIn(400);
                    $(ID).addClass('is-active');
                });


            });

        },

        initForm: function(){

            function isValidEmailAddress(emailAddress) {
                var pattern = new RegExp(/^(("[\w-+\s]+")|([\w-+]+(?:\.[\w-+]+)*)|("[\w-+\s]+")([\w-+]+(?:\.[\w-+]+)*))(@((?:[\w-+]+\.)*\w[\w-+]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][\d]\.|1[\d]{2}\.|[\d]{1,2}\.))((25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\.){2}(25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\]?$)/i);
                return pattern.test(emailAddress);
            };

            var form = $('#js-form');

            if(!form.length)
                return;

            var formContainer = form.find('.form-wrapper');

            $(form).on('submit',function(event){

                event.preventDefault();

                var data = $(this).serializeArray();
                var errors = false;

                if(!form.find('[name=agree]:checked').length){
                    errors = true;
                    $('.form--checkbox').addClass('has-error');
                }

                if(form.find('[name=firstname]').val()===""){
                    errors = true;
                    form.find('[name=firstname]').addClass('has-error');
                }
                if(form.find('[name=lastname]').val()===""){
                    errors = true;
                    form.find('[name=lastname]').addClass('has-error');
                }
                if(form.find('[name=email]').val()==="" || !isValidEmailAddress(form.find('[name=email]').val()) ){
                    errors = true;
                    form.find('[name=email]').addClass('has-error');
                }


                if(!errors){
                    $.ajax({
                        type: "POST",
                        url: './inc.functions.php',
                        data: data,
                        dataType: 'JSON',
                        beforeSend: function(){
                            formContainer.addClass('is-loading');
                        },
                        success: function(response){
                            setTimeout(function(){
                                formContainer.find('.form-message').addClass(response.class);
                                formContainer.find('.form-message').html(response.msg);

                                formContainer.find('.form-elements').slideUp(400,function(){
                                    formContainer.removeClass('is-loading');
                                    formContainer.find('.form-message').slideDown(400);
                                });
                            },400);
                        },
                    });
                }
            })
        },

        initWindowEvents: function () {

            if (this.debug)
                console.log('Window Events :: active');




        },

    };



    MKW.init(true);


}(jQuery));